import {
  ActionIcon,
  Flex,
  Group,
  Input,
  Select,
  TextInput
} from '@mantine/core';
import { IconArrowRight, IconSearch } from '@tabler/icons-react';
import { MantineReactTable, MRT_ColumnDef } from 'mantine-react-table';
import { FilterInput, RetsData, SortDirection } from '../../graphql/graphql';
import { useEffect, useState } from 'react';
import {
  mantinePaginationFilterProps,
  mantinePaperProps,
  mantineTableContainerProps,
  mantineTableHeadProps
} from '../../components/table/mantine-table-props';
import type { ColumnFiltersState } from '@tanstack/react-table';
import { fixColumnDefaults } from '../../components/table/mantine-table-fixes';
import { useLazyQuery, useQuery } from '@apollo/client';
import sessionTableState from '../../components/table/session-table-state';
import useDebounce from '../../hooks/use-debounce';
import useNotification from '../../hooks/use-notification';
import Notification from '../../components/notification/notification';
import {
  GET_RETS_DATA_CUSTOMERS,
  GET_RETS_DATA_PAGE
} from '../../queries/rets-data';
import { useCurrentUser } from '../../app-state';
import { defaultTableFilter } from '../../components/table/table-filter';

export default function RetsDataPage() {
  const user = useCurrentUser();

  /***************************************************************************/
  /* Customers                                                               */
  /***************************************************************************/
  const customersQuery = useQuery(GET_RETS_DATA_CUSTOMERS);
  const [customers, setCustomers] = useState([] as string[]);
  useEffect(() => {
    if (customersQuery.data?.retsDataCustomers) {
      setCustomers(customersQuery.data?.retsDataCustomers);
      if (
        !customersQuery.data.retsDataCustomers.includes(
          table.filter.customerName
        )
      ) {
        table.changeFilter('customerName', null);
      }
    }
  }, [customersQuery.data]);

  const [getData, { data }] = useLazyQuery(GET_RETS_DATA_PAGE);
  const [filteredData, setFilteredData] = useState([] as RetsData[]);
  const [rowCount, setRowCount] = useState(Infinity);

  useEffect(() => {
    if (data?.retsDataPage?.page?.length)
      setFilteredData(data.retsDataPage.page as RetsData[]);
    else setFilteredData([]);
    setRowCount(data?.retsDataPage?.count ? data.retsDataPage.count : Infinity);
  }, [data]);

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  /***************************************************************************/
  /* Notification                                                            */
  /***************************************************************************/
  const notification = useNotification();

  function preview(original: RetsData) {
    location.href =
      '/preview?customerName=' +
      original.sourceCustomerName +
      '&resourceName=' +
      original.sourceResourceName +
      '&sourceUniqueKey=' +
      original.sourceUniqueKey;
  }

  /***************************************************************************/
  /* Table                                                                   */
  /***************************************************************************/

  const table = sessionTableState(
    'rets-datas',
    {
      customerName:
        localStorage.getItem('source_customerName') ||
        user?.getCustomer() ||
        '',
      targetCustomerName:
        localStorage.getItem('customerName') || user?.getTargetCustomer() || '',
      search: '',
      filters: [] as FilterInput[],
      ...defaultTableFilter
    },
    {
      id: false,
      metadataEntryId: false,
      metadataDate: false,
      metadataVersion: false,
      shortValue: false,
      lookupMetadataEntryId: false,
      lookupTypeDate: false,
      lookupTypeVersion: false
    }
  );

  useEffect(() => {
    localStorage.setItem('source_customerName', table.filter.customerName);
  }, [table.filter.customerName]);

  const debounce = useDebounce(250);
  const [lastSearch, setLastSearch] = useState(table.filter.search);

  useEffect(() => {
    if (table.filter.customerName) {
      if (table.filter.search && data?.retsDataPage?.page?.length) {
        setFilteredData(
          ((data.retsDataPage.page as RetsData[])?.filter(
            (rm) =>
              rm.sourceUniqueKey
                ?.toLowerCase()
                .includes(table.filter.search.trim().toLowerCase()) ||
              rm.sourceBoardName
                ?.toLowerCase()
                .includes(table.filter.search.trim().toLowerCase()) ||
              rm.destinationListingKeyAssigned?.includes(
                table.filter.search.trim().toLowerCase()
              ) ||
              rm.destinationDuplicatesListingKey
                ?.toLowerCase()
                .includes(table.filter.search.trim().toLowerCase()) ||
              rm.sourceCustomerName
                ?.toLowerCase()
                .includes(table.filter.search.trim().toLowerCase())
          ) as RetsData[]) || []
        );
      }
      if (
        table.loaded.current &&
        table.filter.search &&
        table.filter.search !== lastSearch
      )
        debounce(() => getData({ variables: table.filter }));
      else getData({ variables: table.filter });
      setLastSearch(table.filter.search);
    } else setFilteredData([]);
  }, [table.filter]);

  const columns: MRT_ColumnDef<RetsData>[] = [
    { header: 'ID', accessorKey: 'id', enableColumnFilter: false },
    { header: 'Source', accessorKey: 'sourceCustomerName' },
    { header: 'Resource', accessorKey: 'sourceResourceName' },
    { header: 'Class', accessorKey: 'sourceClassName' },
    { header: 'Board', accessorKey: 'sourceBoardName' },
    { header: 'Unique Key', accessorKey: 'sourceUniqueKey' },
    { header: 'Listing Key', accessorKey: 'sourceListingKey' },
    { header: 'MLS Status', accessorKey: 'sourceMlsStatus' },
    { header: 'Destination', accessorKey: 'destinationCustomerName' },
    { header: 'Assigned Key', accessorKey: 'destinationListingKeyAssigned' },
    { header: 'Duplicate Key', accessorKey: 'destinationDuplicatesListingKey' }
  ];

  fixColumnDefaults(columns);

  return (
    <>
      <Notification notification={notification} />
      {table.loaded.current && !!user && (
        <MantineReactTable
          columns={columns}
          data={filteredData}
          //table state
          onColumnVisibilityChange={table.setColumnVisibility}
          onDensityChange={table.setDensity}
          onShowColumnFiltersChange={table.setShowColumnFilters}
          onSortingChange={table.setSorting}
          onPaginationChange={table.setPagination}
          state={{
            columnFilters: columnFilters,
            columnVisibility: table.columnVisibility,
            density: table.density,
            showColumnFilters: table.showColumnFilters,
            sorting: table.sorting,
            pagination: table.pagination
          }}
          manualFiltering
          onColumnFiltersChange={(filters) => {
            // Apply the filters updater
            const updatedFilters =
              typeof filters === 'function' ? filters(columnFilters) : filters;

            setColumnFilters(updatedFilters);

            // Update table.filter.filters with the new filters, filtering out cleared filters
            table.filter.filters = updatedFilters
              .filter(
                (cf) =>
                  cf.value !== undefined && cf.value !== null && cf.value !== ''
              )
              .map((cf) => ({
                column: cf.id,
                value: cf.value
              })) as FilterInput[];

            // Trigger data fetch with updated filters
            getData({ variables: table.filter });
          }}
          manualPagination
          pageCount={-1}
          rowCount={rowCount}
          //////////////
          mantinePaperProps={mantinePaperProps}
          mantineTableHeadProps={mantineTableHeadProps}
          mantineTableContainerProps={mantineTableContainerProps}
          mantinePaginationProps={mantinePaginationFilterProps}
          enableColumnOrdering
          enableStickyHeader
          enableStickyFooter
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableColumnFilters={true}
          enableGlobalFilter={false}
          enableRowActions
          renderRowActions={({ row }) => (
            <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
              <ActionIcon onClick={() => preview(row.original)}>
                <IconSearch />
              </ActionIcon>
            </div>
          )}
          renderTopToolbarCustomActions={() =>
            customersQuery.loading ? (
              <></>
            ) : (
              <Group position="apart" spacing="xs" style={{ flexGrow: 1 }}>
                {table.filter.customerName ? (
                  <>
                    <div style={{ position: 'relative', flexGrow: 1 }}>
                      <Input.Label
                        size="xs"
                        style={{
                          position: 'absolute',
                          zIndex: 1,
                          top: -8,
                          left: 6
                        }}
                      >
                        Search
                      </Input.Label>
                      <TextInput
                        placeholder="Search"
                        value={table.filter.search}
                        onChange={(event) =>
                          table.changeFilter('search', event.target.value)
                        }
                        style={{ flexGrow: 1 }}
                      />
                    </div>
                  </>
                ) : (
                  <Flex
                    justify="flex-end"
                    align="center"
                    style={{ flexGrow: 1 }}
                    gap="8px"
                  >
                    <div>Select a Customer to get started</div>
                    <IconArrowRight />
                  </Flex>
                )}
                <div style={{ position: 'relative' }}>
                  <Input.Label
                    size="xs"
                    style={{
                      position: 'absolute',
                      zIndex: 1,
                      top: -8,
                      left: 6
                    }}
                  >
                    Customer
                  </Input.Label>
                  <Select
                    className="tiny-select"
                    withinPortal
                    data={customers}
                    value={table.filter.customerName}
                    onChange={(value) =>
                      table.changeFilter('customerName', value as string)
                    }
                    style={{ width: '6rem' }}
                  />
                </div>
                <div>{/** lazy flex padding */}</div>
              </Group>
            )
          }
        />
      )}
    </>
  );
}
