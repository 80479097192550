import { MRT_ColumnFiltersState, MRT_DensityState, MRT_PaginationState, MRT_SortingState, MRT_VisibilityState } from "mantine-react-table";
import { useEffect, useRef, useState } from "react";
import { SortDirection } from "../../graphql/graphql";
import { TableFilter } from "./table-filter";

export default function sessionTableState<T extends TableFilter>(table: string, defaultFilter: T, defaultColumnVisibility: MRT_VisibilityState) {

  //table state
  const loaded = useRef(false);
  const renderCount = useRef(0);
  const [ columnVisibility, setColumnVisibility ] = useState<MRT_VisibilityState>(defaultColumnVisibility);
  const [ density, setDensity ] = useState<MRT_DensityState>('xs');
  const [ showColumnFilters, setShowColumnFilters ] = useState(true);
  const [ globalFilter, setGlobalFilter ] = useState<string | undefined>(undefined);
  const [ showGlobalFilter, setShowGlobalFilter ] = useState(true);
  const [ _sorting, _setSorting ] = useState<MRT_SortingState>([]);
  const [ _pagination, _setPagination ] = useState<MRT_PaginationState>({ pageIndex: defaultFilter.page || 0, pageSize: defaultFilter.size || 50 });
  const [ _columnFilters, _setColumnFilters ] = useState<MRT_ColumnFiltersState>([]);
  const [ filter, setFilter ] = useState<T>({} as T);

  //load from session storage
  useEffect(() => {
    const columnVisibility = sessionStorage.getItem(table + '_columnVisibility');
    const density = sessionStorage.getItem(table + '_density');
    const showGlobalFilter = sessionStorage.getItem(table + '_showGlobalFilter');
    const globalFilter = sessionStorage.getItem(table + '_globalFilter');
    const showColumnFilters = sessionStorage.getItem(table + '_showColumnFilters');
    const filter = sessionStorage.getItem(table + '_filter');
    if (columnVisibility) setColumnVisibility(JSON.parse(columnVisibility));
    if (density) setDensity(JSON.parse(density));
    if (showGlobalFilter) setShowGlobalFilter(JSON.parse(showGlobalFilter));
    if (globalFilter) setGlobalFilter(JSON.parse(globalFilter) || undefined);
    if (showColumnFilters) setShowColumnFilters(JSON.parse(showColumnFilters));
    if (filter) {
      setFilter({ ...defaultFilter, ...JSON.parse(filter) });
    } else {
      setFilter(defaultFilter);
    }

    //weird bug, needs at least two renders
    renderCount.current++;
    // if (renderCount.current > 1) //breaks the table on UAT for some reason
                                    //(I'm guessing dev has double-render cycles)
      loaded.current = true;
  }, []);
  
  //save to storage on any changes
  useEffect(() => {
    if (!loaded.current) return;
    sessionStorage.setItem(table + '_columnVisibility', JSON.stringify(columnVisibility));
  }, [ columnVisibility ]);
  useEffect(() => {
    if (!loaded.current) return;
    sessionStorage.setItem(table + '_density', JSON.stringify(density));
  }, [ density ]);
  useEffect(() => {
    if (loaded.current) return;
    sessionStorage.setItem(table + '_showGlobalFilter', JSON.stringify(showGlobalFilter));
  }, [ showGlobalFilter ]);
  useEffect(() => {
    if (loaded.current) return;
    sessionStorage.setItem(table + '_globalFilter', JSON.stringify(globalFilter || ''));
  }, [ globalFilter ]);
  useEffect(() => {
    if (!loaded.current) return;
    sessionStorage.setItem(table + '_showColumnFilters', JSON.stringify(showColumnFilters));
  }, [ showColumnFilters ]);
  useEffect(() => {
    if (!loaded.current) return;
    _setSorting([{ id: filter.sort, desc: filter.direction === SortDirection.Desc }]);
    _setPagination({ pageIndex: filter.page, pageSize: filter.size });
    _setColumnFilters(filter.filters?.map((f) => ({ id: f.column || '', value: f.value })) || []);
    sessionStorage.setItem(table + '_filter', JSON.stringify(filter));
  }, [ filter ]);

  function setSorting(sorting: MRT_SortingState | ((state: MRT_SortingState) => MRT_SortingState)) {
    if (!loaded.current) return;
    if (typeof sorting === 'function') sorting = sorting(_sorting);
    if (
      filter.sort !== sorting[0]?.id ||
      filter.direction !== (sorting[0]?.desc ? SortDirection.Desc : SortDirection.Asc)
    ) {
      setFilter({ ...filter, sort: sorting[0]?.id || 'id', direction: sorting[0]?.desc ? SortDirection.Desc : SortDirection.Asc });
    }
  }

  function setPagination(pagination: MRT_PaginationState | ((state: MRT_PaginationState) => MRT_PaginationState)) {
    if (!loaded.current) return;
    if (typeof pagination === 'function') pagination = pagination(_pagination);
    if (
      filter.page !== pagination.pageIndex ||
      filter.size !== pagination.pageSize
    ) {
      setFilter({ ...filter, page: pagination.pageIndex || 0, size: pagination.pageSize || 50 });
    }
  }

  function setColumnFilters(columnFilters: MRT_ColumnFiltersState | ((state: MRT_ColumnFiltersState) => MRT_ColumnFiltersState)) {
    if (!loaded.current) return;
    if (typeof columnFilters === 'function') columnFilters = columnFilters(_columnFilters);
    let filters = columnFilters.filter(cf => cf.id && cf.value).map(cf => ({ column: cf.id, value: cf.value }));
    if (
      filter.filters?.length !== filters.length ||
      filter.filters?.map(f => (f.column || '') + (f.value || '')).join(',') !== filters.map(f => (f.column || '') + (f.value || '')).join(',')
    ) {
      setFilter({ ...filter, filters: filters });
    }
  }

  function changeFilter(key: string, value: any | string | number | null) {
    setFilter({ ...filter, [key]: value });
  }

  return {
    loaded,
    columnVisibility, setColumnVisibility,
    density, setDensity,
    showColumnFilters, setShowColumnFilters,
    showGlobalFilter, setShowGlobalFilter,
    globalFilter, setGlobalFilter,
    sorting: _sorting, setSorting,
    pagination: _pagination, setPagination,
    columnFilters: _columnFilters, setColumnFilters,
    filter, setFilter, changeFilter
  }
}
